import request from "@/common/utils/request.js";
import {
  WebClient
} from "@/api/webClient";
let webClient = new WebClient();

function getListByTenantId(params) {
  let data = request({
    url: `/market/trainingCampToPC/getListByTenantId`,
    method: "GET",
    params: params,
  });
  webClient.functionNo(data);
  return data;
}

/**M-获取训练营详情*/
function getAllDetail(params) {
  let data = request({
    url: `/market/trainingCampToPC/getAllDetail`,
    method: "GET",
    params: params,
  });
  webClient.functionNo(data);
  return data;
}
/**PC-添加学习记录*/
function addLog(params) {
  let data = request({
    url: `/market/trainingCampToPC/addLog`,
    method: "GET",
    params: params,
  });
  webClient.functionNo(data);
  return data;
}

export {
  getListByTenantId,
  getAllDetail,
  addLog
};