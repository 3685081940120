<template>
  <div class="wrap">
    <!-- 视频内容 -->
    <div class="video-box" v-if="IsPurchase">
      <!-- (getExplorer == 'Chrome' || status != 0 || classProductLine != 3) && -->
      <!-- 直播回放 -->
      <video-room
        ref="videoRoom"
        v-if="IsPurchase && isLive == 3"
        :isLive="isLive"
        :startTime="startTime"
        :detail="detail"
        :courseId="courseId"
        :showId="id"
        :classId="classId"
        :type="1"
        :roomid="isLive == 1 ? liveInfo.roomid : ccRomid"
        :recordid="isLive == 1 ? liveInfo.recordid : recordId"
        :IsPurchase="IsPurchase"
        userid="41A331E332E32281"
        :classProductLine="classProductLine"
        :aliVideoUrl="aliVideoUrl"
      />
      <!-- :status="status" -->
      <!-- (isLive == 3 && !aliVideoUrl) || -->

      <!-- v-if="IsPurchase && liveInfo.roomid && liveInfo.recordid && isLive == 1 && genre == 2" -->
      <!-- 伪直播 -->
      <live
        ref="live"
        v-if="IsPurchase && ccRomid && recordId && isLive == 1 && genre == 2"
        :roomid="ccRomid"
        :recordid="recordId"
        :isLive="isLive"
        :startTime="startTime"
        :detail="detail"
        :courseId="courseId"
        :showId="id"
        :classId="classId"
        :type="1"
        :IsPurchase="IsPurchase"
        userid="41A331E332E32281"
        :classProductLine="classProductLine"
      />
      <!--  :status="status" -->
      <!-- 直播 -->
      <cc-live
        v-if="isLive == 1 && genre == 1 && IsPurchase"
        :ccConfig="ccConfig"
        :detail="detail"
        :courseId="courseId"
        :showId="id"
        :classId="classId"
        :classProductLine="classProductLine"
      ></cc-live>
      <!-- <live-room
        :classId="classId"
        :courseId="courseId"
        :detail="detail"
        :showId="id"
        :type="1"
        v-if="isLive == 1 && genre == 1 && IsPurchase"
        :ccConfig="ccConfig"
        :IsPurchase="IsPurchase"
      /> -->
    </div>

    <div class="wrap-play" v-else></div>
    <div class="btm">
      <div class="bhj">环节目录</div>
      <div class="bhjlist">
        <div
          @click="godetail(item)"
          :class="item.isclick ? 'bl' : 'bl bls'"
          v-for="(item, index) in classrow.tasks"
          :key="index"
        >
          <div v-if="item.isclick" class="sj"><img src="@/assets/img/markcamp/sj.png" alt="" /></div>
          <div class="ic">
            <img v-if="item.isLive == 0" src="@/assets/img/markcamp/s.png" alt="" />
            <img v-else src="@/assets/img/markcamp/z.png" alt="" />
          </div>
          <div class="yy">
            <span>{{ index < 10 ? '0' + (index + 1) : index + 1 }}</span> {{ item.name }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Bus from '@/api/bus'
import Vue from 'vue'
import { getLbClassCourseListById, getLbClassCourseById } from '@/api/home'
import LiveRoom from '@/components/Know/liveRoom.vue'
import videoRoom from '@/components/Know/videoRooms.vue'
import live from '@/components/Know/lives.vue'
import scoreEvaluation from '@/components/Know/course/scoreEvaluation.vue'
import answerView from '@/components/Know/course/answerView.vue'
import { OrderClient } from '@/api/orderClient'
let orderClient = new OrderClient()
import { Know } from '@/api/know'
let know = new Know()
import format from 'date-fns/format'
import { getLbClassCourse } from '@/api/mykecheng'
import { getInfo, getDomain } from '@/api/cookies'
import ccLive from '@/components/Know/ccLives.vue'
import { addLog, getAllDetail } from '@/api/markcamp'

export default {
  components: {
    scoreEvaluation,
    answerView,
    LiveRoom,
    videoRoom,
    live,
    ccLive,
  },
  data() {
    return {
      copyright: {}, //机构信息
      userInfo: {},
      NavIndex: 1,
      id: null,
      classId: null,
      courseId: null,
      detail: {},
      isLive: null,
      ccConfig: null,
      ccRomid: null,
      recordId: null,
      IsPurchase: true,
      ifHave: false,
      liveInfo: {},
      liveshowplaybackList: [],
      startTime: '', //  开始直播时间
      live: null,
      /* 学习记录 */
      setIntervals: null,
      classProductLine: 0,
      status: 0,
      getExplorer: '', //当前浏览器
      genre: null, // 1 直播 2 伪直播
      aliVideoUrl: null, // 阿里回放vid
      allmsg: {},
    }
  },
  async created() {
    this.userInfo = getInfo()
    this.trainingId = this.$route.query.id
    this.tid = this.$route.query.tid
    this.cid = this.$route.query.cid
    this.copyright = getDomain()
    await getAllDetail({
      userid: this.userInfo ? this.userInfo.id : undefined,
      trainingId: this.trainingId ? this.trainingId : undefined,
    }).then((res) => {
      for (let items of res.data.links) {
        if (items.id == this.tid) {
          this.classrow = items
          this.classrow.tasks.forEach((e) => {
            e.isclick = false
          })
          // this.classrow.tasks[0].isclick = true
          for (let itemss of this.classrow.tasks) {
            if (itemss.id == this.cid) {
              this.rowitem = itemss
              itemss.isclick = true
              console.log(this.rowitem, 'this.rowitem ')
              this.id = this.rowitem.typeId
              this.startTime = this.rowitem.startTime
              this.classId = this.rowitem.classId
              this.classProductLine = this.rowitem.classProductLine ? Number(rowitem.classProductLine) : 0
            }
          }
        }
      }
      this.allmsg = res.data
      // this.allmsg = res.data
      // this.classrow.tasks.forEach((e) => {
      //   e.isclick = false
      // })
      // this.classrow.tasks[0].isclick = true
      this.$forceUpdate()
    })
    // this.classrow = JSON.parse(localStorage.getItem('classrow'))
    // this.rowitem = JSON.parse(localStorage.getItem('rowitem'))
    // this.classrow = this.$route.query.classrow

    await this.getDetail()
    await this.findTrackingVideoRecord()
    /*  this.getExplorer = await Vue.prototype.getExplorer();
    if (
      this.classProductLine == 3 &&
      this.getExplorer != "Chrome" &&
      this.status == 0 &&
      this.isLive == 3
    ) {
      this.$message.error("请使用谷歌浏览器观看");
      return;
    } */
    if (this.isLive == 1) {
      this.NavIndex = 2
    }
  },
  beforeDestroy() {
    this.getLbClassCourse()
    clearInterval(this.setIntervals)
  },
  methods: {
    /* 查询课程章节是否已观看 */
    async findTrackingVideoRecord() {
      /*  await know
        .findTrackingVideoRecord(
          this.courseId,
          this.id,
          1,
          this.classId ? this.classId : null
        )
        .then((res) => {
          this.status = res.data.status;
        }); */
    },
    async getDetail() {
      await getLbClassCourseListById(this.id).then((res) => {
        console.log(res.data, 'res.data')
        this.aliVideoUrl = res.data.msg.aliVideoUrl
        this.detail = res.data.msg
        this.courseId = res.data.msg.liveId
        this.isLive = res.data.msg.isLive
        this.ccRomid = res.data.msg.ccRomid
        this.recordId = res.data.msg.recordId
      })
      await getLbClassCourseById(this.courseId, this.classId ? this.classId : undefined).then((res) => {
        this.genre = res.data.details.genre
        this.liveshowplaybackList = res.data.details.liveshowplaybackList
        this.ccConfig = res.data.details.ccConfig
        this.live = res.data.details
        if (this.ccConfig) {
          this.cutting()
        }
        this.$forceUpdate()
      })
      // if (this.isLive == 1) {
      this.getLbClassCourse()
      // this.$forceUpdate()
      // }
    },
    // 立即购买
    goOrder() {
      if (this.userInfo) {
        orderClient
          .addOrders(this.courseId, this.userInfo.id, this.live.title, 2, this.live.title, this.live.kind)
          .then((res) => {
            if (res.code == 0) {
              this.$router.push({
                name: '个人中心订单页',
                params: { orderNumber: res.msg },
              })
            } else {
              this.$message.error(res.msg)
            }
          })
      } else {
        Vue.prototype.goLoginView(true)
      }
    },
    /*  */
    /* https://view.csslcloud.net/api/view/callback?roomid=A3AC4528EB09D2E09C33DC5901307461&userid=41A331E332E32281&recordid=7B6BFF4DAB1FFB8B&liveid=A558277FB7900D6B */
    cutting() {
      let index = this.ccConfig.indexOf('?')
      if (index != -1) {
        let str = this.ccConfig.substr(index + 1)
        let strs = str.split('&')
        for (var i = 0; i < strs.length; i++) {
          this.liveInfo[strs[i].split('=')[0]] = strs[i].split('=')[1]
        }
      }
      console.log(this.liveInfo, '==============this.liveInfo')
    },
    /* --------学习记录--------------- */
    /* 添加记录 */
    async getLbClassCourse() {
      console.log('========getLbClassCourse========')
    },
    async godetail(item) {
      if (!this.userInfo) {
        localStorage.setItem('rowitem', '')
        this.$router.push({
          path: '/markcamp/camplist',
        })
      } else {
        this.rowitem = item
        console.log(this.rowitem, ' this.rowitemll')
        localStorage.setItem('rowitem', JSON.stringify(item))
        this.time = item.startTime
        this.classrow.tasks.forEach((e) => {
          if (item.id == e.id) {
            e.isclick = true
          } else {
            e.isclick = false
          }
        })
        if (item.isLive == 0) {
          this.$message({
            message: '直播未开始',
            type: 'warning',
          })
        } else if (item.isLive == 2) {
          this.$message({
            message: '回放生成中',
            type: 'warning',
          })
        } else {
          addLog({
            studentId: this.userInfo ? this.userInfo.id : undefined,
            campId: this.allmsg.id ? this.allmsg.id : undefined,
            linkId: this.classrow.id ? this.classrow.id : undefined,
            taskId: item.id ? item.id : undefined,
          })
          window.open(
            `/markcamp/zbclass?id=${this.trainingId}&tid=${this.tid}&cid=${this.rowitem.id}`,
            '_blank'
          )

          // window.open('/markcamp/zbclass', '_blank')
          // this.id = item.typeId
          // this.startTime = item.startTime
          // this.classId = item.classId ? item.classId : null
          // this.classProductLine = item.classProductLine ? Number(item.classProductLine) : 0
          // await this.getDetail()
          // await this.findTrackingVideoRecord()

          // if (this.isLive == 1) {
          //   this.NavIndex = 2
          // }
        }
      }

      // this.$forceUpdate()
    },
  },
}
</script>

<style lang="less" scoped>
.video-box {
  position: relative;
  #liveLog {
    position: absolute;
    right: 310px;
    bottom: 90px;
    z-index: 99999;
    img {
      // width: 100%;
      height: 40px;
    }
  }
  #logo {
    position: absolute;
    right: calc(50% - 610px + 380px);
    bottom: 150px;
    z-index: 99999;
    img {
      // width: 100%;
      height: 40px;
    }
  }
}

.wrap {
  width: 100%;
  height: 100%;
  padding-bottom: 40px;
}
.wrap-play {
  height: 617px;
  background: #222222;
}
.content {
  width: 1220px;
  background: #ffffff;
  margin: 20px auto;
}
.btm {
  width: 1233px;
  margin: 0 auto;
  margin-top: 27px;

  .bhj {
    width: 827px;
    height: 66px;
    background: #ffffff;
    border-radius: 4px;
    font-size: 20px;
    font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
    font-weight: bold;
    color: #333333;
    line-height: 66px;
    padding-left: 28px;
  }
  .bhjlist {
    width: 827px;
    background: #ffffff;
    border-radius: 4px;
    padding-bottom: 70px;
    margin-top: 20px;
    .bl {
      width: 749px;
      display: flex;
      align-items: center;
      height: 62px;
      border-bottom: 1px solid #ededed;
      margin: 0 auto;
      font-size: 18px;
      font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
      font-weight: bold;
      color: #333333;
      position: relative;
      cursor: pointer;
      .ic {
        width: 20px;
        height: 20px;
        margin-right: 12px;

        img {
          width: 100%;
          height: 100%;
        }
      }
    }
    .bls {
      font-size: 18px;
      font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
      font-weight: bold;
      color: #555555;
    }
  }
  .bl:last-child {
    border-bottom: 0;
  }
}
.sj {
  position: absolute;
  left: -37px;
  width: 10px;
  height: 15px;
  img {
    width: 100%;
    height: 100%;
  }
}
.yy {
  display: block;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 500px;
}
</style>
