<template>
  <div class="videoRoom">
    <!-- <div> -->
    <!-- 左视频 -->
    <div class="room-left">
      <div class="room-title">
        <div class="elp">
          {{ rowitem.name }}
        </div>
        <div class="time">
          <i class="iconfont icon-bofangzhongbeifen"></i>
          直播时间：{{ detail.startTime | format('yyyy.MM.dd HH:mm')
          }}
        </div>
      </div>
      <!-- 视频上方提示 -->
      <div class="room-Top" v-if="tipsShow">
        <!-- 左侧提示 -->
        <div class="wrapTopsCenTopL">
          <img src="@/assets/img/kecheng/tipsIcon.png" />
          <span
            >课程视频版权所有，禁止任何形式得转载！并未经本公司书面许可的使用行为，我公司均保留追究法律责任的权利。</span
          >
        </div>
        <!-- 右侧关闭 -->
        <div class="wrapTopsCenTopR" @click="tipsShow = false">
          <span>关闭</span>
          <img src="@/assets/img/kecheng/classIcon1123.png" />
        </div>
      </div>
      <!-- 视频模块： -->
      <div
        v-if="!aliVideoUrl"
        id="playbackPanel"
        :class="fullScreenInfo ? 'full_screen' : ''"
      >
        <div class="iconfont icon-quanping1" @click="fullScreen"></div>
        <div class="playbackRate_box">
          <div class="playbackRate_btn">
            倍速
            <div class="playbackRate_item_box">
              <div
                :class="times == item.value ? 'on' : ''"
                class="playbackRate_item"
                v-for="item in playbackRateList"
                :key="item.value"
                @click="changePlaybackRate(item.value, 'cc')"
              >
                {{ item.name }}
              </div>
            </div>
          </div>
        </div>
        <div id="logo">
          <img :src="copyright.videoLogo" style="object-fit: contain" />
        </div>
      </div>
      <div v-else id="J_prismPlayer">
        <div id="logo">
          <img :src="copyright.videoLogo" style="object-fit: contain" />
        </div>
        <div class="playbackRate_box">
          <div class="playbackRate_btn">
            倍速
            <div class="playbackRate_item_box">
              <div
                :class="times == item.value ? 'on' : ''"
                class="playbackRate_item"
                v-for="item in playbackRateList"
                :key="item.value"
                @click="changePlaybackRate(item.value, 'ali')"
              >
                {{ item.name }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 右头像/聊天 -->
    <div class="room-right">
      <!-- 讲师头像 -->
      <div id="playbackPlayer"></div>
    </div>
    <!-- </div> -->
    <div class="haveNot" @touchmove.stop.prevent="stop()" v-if="!IsPurchase">
      <div>
        <img class="imga" src="@/assets/img/kecheng/No1.png" />
        <img
          class="imgb"
          src="@/assets/img/kecheng/close.png"
          @click.stop="goBack()"
        />
        <span>您暂未购买该课程哦~</span>
      </div>
    </div>
  </div>
</template>
<script type="text/javascript">
import {
  getLbClassCourseById,
  selectSupplyToStudentStudyRate
} from '@/api/home'
import { getInfo, getDomain } from '@/api/cookies'
import { Know, sendLiveVideoHistoryBatch, playAuth } from '@/api/know'
let know = new Know()

import liveboFangChater from '@/components/Know/liveboFangChater.vue'
export default {
  components: { liveboFangChater },
  props: [
    'detail',
    'userid',
    'roomid',
    'recordid',
    'courseId',
    'classId',
    'showId',
    'type',
    'ccConfig',
    'IsPurchase',
    'classProductLine',
    'status',
    'aliVideoUrl'
  ],
  /*type 1 直播  2录播 录播  3公开*/
  data() {
    return {
      copyright: {}, //机构信息

      tipsShow: true,
      //直播 用户名称
      viewername: '',
      // 公聊
      msgList: [],
      // 输入框
      my_msg: '',
      timer: 0,
      liveInfo: {},
      liveDetail: {
        userid: '',
        roomid: '',
        recordid: ''
      }, //课程详情
      /* 学习记录 */
      setIntervals: null,
      histor: 0, //历史播放时间
      intervalTimer: null,
      playauth: null, // 阿里加密播放参数
      liveTime: 0,
      firstPlayFlag: true,
      player: null,

      playbackRateList: [
        { name: '0.5X', value: 0.5 },
        { name: '1.0X', value: 1 },
        { name: '1.5X', value: 1.5 }
      ],
      times: 1,
      fullScreenInfo: false,
      isfullscreen: false
    }
  },
  async beforeDestroy() {
    await this.postLiveTime()
    clearInterval(this.setIntervals)
    if (!this.aliVideoUrl) {
      $.DW.destroy()
    }

    /*    $.DW.destroy(); */
    if (!this.aliVideoUrl) {
      $.DW.destroy()
    }
  },
  watch: {
    isfullscreen() {
      if (!this.isfullscreen) {
        this.fullScreenInfo = false
      }
    }
  },
  async created() {
    this.rowitem = JSON.parse(localStorage.getItem('rowitem'))
    console.log(this.aliVideoUrl, 'this.aliVideoUrl')
    this.copyright = getDomain()
    this.userInfo = getInfo()
    if (!this.IsPurchase) {
      return
    }
    getLbClassCourseById(this.courseId).then((res) => {
      this.liveDetail = res.data.details
    })
    await this.getTIme()
    if (this.aliVideoUrl) {
      this.aliPlay()
    } else {
      this.ifCcConfig()
    }
    this.$forceUpdate()
  },

  mounted() {
    //添加监听事件
    window.addEventListener('beforeunload', (e) => this.beforeunloadHandler(e))

    document.addEventListener('fullscreenchange', () => {
      this.isfullscreen = !this.isfullscreen
    })
    document.addEventListener('mozfullscreenchange', () => {
      this.isfullscreen = !this.isfullscreen
    })
    document.addEventListener('webkitfullscreenchange', () => {
      this.isfullscreen = !this.isfullscreen
    })
    document.addEventListener('msfullscreenchange', () => {
      this.isfullscreen = !this.isfullscreen
    })
    this.onbeforeunload()
  },
  destroyed() {
    window.removeEventListener('beforeunload', (e) => this.beforeunloadFn(e))
  },
  methods: {
    beforeunloadHandler(e) {
      this.postLiveTime()
    },
    fullScreen() {
      let fullscreenElement = document.fullscreenElement
      let element = document.documentElement
      if (fullscreenElement) {
        /* 退出全屏 */
        if (document.exitFullscreen) {
          document.exitFullscreen()
        } else if (document.webkitCancelFullScreen) {
          document.webkitCancelFullScreen()
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen()
        } else if (document.msExitFullscreen) {
          document.msExitFullscreen()
        }
        this.fullScreenInfo = false
      } else {
        /* 进入全屏 */
        if (element.requestFullscreen) {
          element.requestFullscreen()
        } else if (element.mozRequestFullScreen) {
          element.mozRequestFullScreen()
        } else if (element.msRequestFullscreen) {
          element.msRequestFullscreen()
        } else if (element.webkitRequestFullscreen) {
          element.webkitRequestFullscreen()
        }
        this.fullScreenInfo = true
      }
    },
    async ifCcConfig() {
      if (this.ccConfig) {
        let index = this.ccConfig.indexOf('?')
        if (index != -1) {
          var str = this.ccConfig.substr(index + 1)
          var strs = str.split('&')
          for (var i = 0; i < strs.length; i++) {
            this.liveInfo[strs[i].split('=')[0]] = strs[i].split('=')[1]
          }
        }
      } else {
        this.liveInfo.userid = '41A331E332E32281'
        this.liveInfo.roomid = this.roomid
        this.liveInfo.recordid = this.recordid
      }

      this.$nextTick(function () {
        this.liveStart()
      })
    },
    async aliPlay() {
      await playAuth(this.aliVideoUrl)
        .then((res) => {
          this.playauth = res.data
        })
        .then(() => {
          this.player = new Aliplayer({
            id: 'J_prismPlayer',
            width: '100%',
            vid: this.aliVideoUrl,
            playauth: this.playauth,
            encryptType: 1,
            skinLayout: [
              {
                name: 'bigPlayButton',
                align: 'blabs',
                x: 30,
                y: 80
              },
              {
                name: 'H5Loading',
                align: 'cc'
              },
              {
                name: 'errorDisplay',
                align: 'tlabs',
                x: 0,
                y: 0
              },
              {
                name: 'infoDisplay'
              },
              {
                name: 'tooltip',
                align: 'blabs',
                x: 0,
                y: 56
              },
              {
                name: 'thumbnail'
              },
              {
                name: 'controlBar',
                align: 'blabs',
                x: 0,
                y: 0,
                children: [
                  {
                    name: 'progress',
                    align: 'blabs',
                    x: 0,
                    y: 44
                  },
                  {
                    name: 'playButton',
                    align: 'tl',
                    x: 15,
                    y: 12
                  },
                  {
                    name: 'timeDisplay',
                    align: 'tl',
                    x: 10,
                    y: 7
                  },
                  {
                    name: 'fullScreenButton',
                    align: 'tr',
                    x: 10,
                    y: 12
                  },
                  {
                    name: 'subtitle',
                    align: 'tr',
                    x: 15,
                    y: 12
                  },
                  {
                    name: 'volume',
                    align: 'tr',
                    x: 5,
                    y: 10
                  }
                ]
              }
            ]
          })
          this.player.on('play', () => {
            this.timeInterval()
          })
          this.onbeforeunload()
        })
      this.player.seek(this.histor)
      await selectSupplyToStudentStudyRate({
        userId: this.userInfo.id,
        showId: this.showId,
        courseType: 1
      })
    },
    onbeforeunload() {
      let _this = this
      window.removeEventListener('beforeunload', (e) => this.beforeunloadFn(e))
    },
    timeInterval() {
      let currTime, rate
      if (this.aliVideoUrl) {
        let J_prismPlayer = document.querySelector('#J_prismPlayer')
        this.liveTime = Math.floor(this.player.getCurrentTime())
        currTime = Math.floor(this.player.getCurrentTime() * 1000)
        rate = J_prismPlayer.querySelector('video').playbackRate
      } else {
        this.liveTime = Math.floor($.DW.getPlayerTime())
        currTime = Math.floor($.DW.getPlayerTime() * 1000)
        rate = $('#playbackVideo').get(0).playbackRate
      }
      rate = parseFloat(rate)
      this.getLbClassCourse(currTime, rate)
      this.intervalTimer = setTimeout(
        this.timeInterval,
        1000 /* Math.round((1000 - 100) / rate) */
      )
    },
    cleanTimeInterval() {
      clearTimeout(this.intervalTimer)
      this.intervalTimer = null
    },
    /* 添加播放历史 */
    async postLiveTime() {
      await know
        .addStudentLearningPlaybackRecords(
          1, // 张永涛说回放属于直播类型
          this.userInfo.id,
          this.courseId,
          this.showId,
          Math.round(this.liveTime)
        )
        .then((res) => {
          console.log(res, '添加历史')
        })
    },
    /* 获取播放历史 */
    async getTIme() {
      await know
        .getStudentLearningPlaybackRecords(
          1,
          this.userInfo.id,
          this.courseId,
          this.showId
        )
        .then((res) => {
          if (res.code == 0) {
            if (res.msg && res.msg.duration) {
              this.histor = res.msg.duration
              /* $.DW.seek(this.histor); */
            }
          }
        })
    },
    stop() {
      return
    },
    // 初始化
    async liveStart() {
      let that = this
      setTimeout(() => {
        $.DW.config({
          userId: this.liveInfo.userid,
          roomId: this.liveInfo.roomid, //直播间Id
          recordId: this.liveInfo.recordid, //直播回放Id
          isH5play: true,
          fastMode: true
        })
        //动态显示登陆名
        window.onload = function () {
          that.getLbClassCourse()
        }
        function on_cc_login_error() {
          console.log('登录失败')
        }

        function on_cc_login_success(data) {
          console.log('登录成功')
        }
        
      }, 100)
      // $.DW.isShowBar(1);
      window.on_cc_live_chat_msg_sync = function (msg) {
        that.msgList = that.msgList.concat(msg)
        that.$forceUpdate()
        that.scrollbottom()
      }
      window.on_cc_live_player_load = () => {
        setTimeout(() => {
          if (that.firstPlayFlag) {
            $.DW.seek(that.histor)
            that.firstPlayFlag = false
          }
        }, 1000)
      }
      window.on_player_start = () => {
        //直播开始
        if (this.intervalTimer == null) {
          this.timeInterval()
          let playbackVideo = document.querySelector('#playbackVideo')
          playbackVideo.setAttribute('controlsList', 'noplaybackrate ')
        }
      }
      window.on_spark_player_pause = () => {
        //播放暂停。
        this.cleanTimeInterval()
      }
      window.on_spark_player_resume = () => {
        //恢复播放
        console.log('恢复播放')
        if (this.intervalTimer == null) {
          this.timeInterval()
        }
      }
      window.on_spark_player_end = () => {
        //播放停止
        this.cleanTimeInterval()
      }
      await selectSupplyToStudentStudyRate({
        userId: this.userInfo.id,
        showId: this.showId,
        courseType: 1
      })
      this.timeCHange()
    },
    timeCHange() {
      this.postLiveTime()
    },
    scrollbottom() {
      this.$nextTick(() => {
        this.$refs.chatContent.scrollTop = this.$refs.chatContent.scrollHeight
      })
    },
    changePlaybackRate(val, type) {
      if (type == 'cc') {
        this.times = val
        $.DW.playbackRate(val)
      } else if (type == 'ali') {
        this.times = val
        this.player.setSpeed(val)
      }
    },
    /* --------学习记录--------------- */
    /* 添加记录 */
    async getLbClassCourse(currTime, rate) {
      if (!this.IsPurchase || !this.userInfo) {
        return
      }

      let timePoint = Math.floor(currTime / 1000)
      this.timePointBatch.push(timePoint)
      if( (currTime%30) == 0 ){//每30s发送一次
        this.sendTimePointBatch(rate)
      }

      
    },


    async sendTimePointBatch(rate){
      // 向后台发送学习率数据；
      const postData = {
        userId: this.userInfo.id,
        classId: this.classId ? this.classId : 0,
        courseId: this.courseId,
        showId: this.showId,
        tenantId: this.userInfo.tenantId,
        timePointBatch: this.timePointBatch,
        multiple: rate,
        duration: this.aliVideoUrl
          ? parseInt(this.player.getDuration())
          : parseInt($.DW.getDuration()),
        courseType: 1,
        agentType: 0,
        // isLive: 0,
        isLive: 2,
        liveBeginTime: this.detail.startTime
      }
      sendLiveVideoHistoryBatch(postData).then( (res) => {

        if (res.code != 500) {//成功，清空秒点数组
          //批量上报成功，清空数组
          this.timePointBatch = []//清空
        }

      } )
    }

  }
}
</script>
<style lang="less" scoped>
// 视频上方提示
.room-Top {
  width: 100%;
  height: 40px;
  background: #ffffff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  top: 58px;
  z-index: 99;
  // 左侧提示
  .wrapTopsCenTopL {
    width: auto;
    height: 16px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    img {
      width: 16px;
      height: 16px;
      margin: 0px 10px 0px 10px;
    }
    span {
      width: auto;
      height: auto;
      font-size: 12px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #f15817;
    }
  }
  // 右侧关闭
  .wrapTopsCenTopR {
    width: auto;
    height: 12px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    cursor: pointer;
    span {
      width: auto;
      height: auto;
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #a8abbe;
    }
    img {
      width: 11px;
      height: 11px;
      margin: 0px 10px 0px 5px;
    }
  }
}
/* 讲师 */
.detail-ter {
  width: 100%;
  height: 58px;
  background: #383838;
  border-radius: 8px 8px 8px 8px;
  opacity: 1;
  padding: 0px 20px;
  .ter {
    color: #fff;
  }
}
/* 章节名称 */
.room-title {
  height: 58px;
  background: #383838;
  border-radius: 8px 8px 0px 0px;
  width: 100%;
  overflow: hidden;
  scrollbar-width: none;
  opacity: 1;
  font-size: 22px;
  font-family: Microsoft YaHei-Regular;
  font-weight: 400;
  color: #ffffff;
  line-height: 22px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 20px;
  .time {
    font-size: 14px;
    font-family: Microsoft YaHei-Regular;
    font-weight: 400;
    color: #ff5e51;
    flex-shrink: 0;
    line-height: 14px;
    .iconfont {
      font-size: 14px;
      margin-right: 4px;
    }
  }
}
// 中间视频
.videoRoom {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  padding: 32px 0px;
  background: #222222;
  // 左视频
  .room-left {
    width: 880px;
    height: 100%;
    background: #222222;
    margin-right: 15px;
    position: relative;
    #playbackPanel {
      width: 100%;
      height: 495px;
      border-radius: 0px 0px 10px 10px;
      opacity: 1;
      position: relative;
      .icon-quanping1 {
        position: absolute;
        top: 20px;
        right: 20px;
        cursor: pointer;
      }
      .playbackRate_box {
        display: none;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 50px;
        background-color: rgba(0, 0, 0, 0.5);
        z-index: 999999;
        .playbackRate_btn {
          position: absolute;
          top: 10px;
          right: 10px;
          color: #fff;
          width: 80px;
          padding: 5px 0;
          border: 1px solid #fff;
          border-radius: 20px;
          text-align: center;
          cursor: pointer;
          .playbackRate_item_box {
            display: none;
            flex-direction: column;
            justify-content: space-around;
            background-color: rgba(0, 0, 0, 0.5);
            // background-color: red;
            width: 80px;
            height: 100px;
            position: absolute;
            right: 0;
            top: -100px;
            border-radius: 10px;
            .playbackRate_item {
              color: #fff;
            }
            .on {
              color: #00a2e9;
            }
          }
        }
        .playbackRate_btn:hover {
          border: 1px solid #00a2e9;
          color: #00a2e9;
          transition: 100ms;
        }
        .playbackRate_btn:hover .playbackRate_item_box {
          display: flex;
        }
      }
    }
    #playbackPanel:hover .playbackRate_box {
      display: block;
    }
    #J_prismPlayer {
      position: relative;
      .playbackRate_box {
        display: none;
        position: absolute;
        bottom: 0;
        right: 100px;
        width: 100px;
        height: 50px;
        z-index: 999999;
        .playbackRate_btn {
          position: absolute;
          top: 10px;
          right: 10px;
          color: #fff;
          width: 80px;
          padding: 5px 0;
          border-radius: 20px;
          text-align: center;
          cursor: pointer;
          .playbackRate_item_box {
            display: none;
            flex-direction: column;
            justify-content: space-around;
            background-color: rgba(0, 0, 0, 0.5);
            // background-color: red;
            width: 80px;
            height: 100px;
            position: absolute;
            right: 0;
            top: -100px;
            border-radius: 10px;
            .playbackRate_item {
              color: #fff;
            }
            .on {
              color: #00a2e9;
            }
          }
        }
        .playbackRate_btn:hover {
          color: #00a2e9;
          transition: 100ms;
        }
        .playbackRate_btn:hover .playbackRate_item_box {
          display: flex;
        }
      }
    }
    #J_prismPlayer:hover .playbackRate_box {
      display: block;
    }
    .prism-player {
      width: 100%;
      height: 100%;
    }
    video {
      width: 100%;
      height: 100%;
    }
  }
  // 右头像/聊天
  .room-right {
    height: 100%;
    flex-shrink: 0;
    #playbackPlayer {
      width: 320px;
      height: 240px;
      border-radius: 8px 8px 8px 8px;
      opacity: 1;
      overflow: hidden;
      margin-bottom: 20px;
    }
    img {
      width: 135px;
      height: 113px;
      margin-top: 26px;
    }
    span {
      width: auto;
      height: auto;
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #6a6a6a;
    }
    .room {
      width: 320px;
      height: 371px;
      background: #383838;
      border-radius: 8px 8px 8px 8px;
      opacity: 1;
      overflow: hidden;
      overflow-y: scroll;
    }
  }
  // 聊天框
  .wrapTopsCenCenRC {
    width: 100%;
    height: 346px;
    // 聊天框顶部
    .wrapTopsCenCenRCa {
      width: 100%;
      height: 40px;
      background: #464646;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #efefef;
    }
    // 聊天框内容
    .wrapTopsCenCenRCb {
      width: 100%;
      height: 260px;
      overflow: auto;
      // 内容盒子
      .wrapTopsCenCenRCbs {
        width: 85%;
        height: auto;
        margin: 20px auto;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        // 左侧头像
        .wrapTopsCenCenRCbsL {
          width: 32px;
          height: 30px;
          border-radius: 50%;
          margin-right: 10px;
          img {
            width: 100%;
            height: 100%;
            border-radius: 50%;
          }
        }
        // 右侧昵称/留言
        .wrapTopsCenCenRCbsR {
          width: 85%;
          height: auto;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: flex-start;
          span {
            width: auto;
            height: auto;
            font-size: 12px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #b0b0b0;
          }
          p {
            width: 100%;
            height: auto;
            overflow: hidden;
            font-size: 12px;
            margin-top: 5px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #ffffff;
          }
        }
      }
    }
    // 聊天框底部
    .wrapTopsCenCenRCc {
      width: 100%;
      height: 46px;
      background: #5d5d5d;
      display: flex;
      justify-content: center;
      align-items: center;
      // 内容盒子
      .wrapTopsCenCenRCcs {
        width: 85%;
        height: 30px;
        background: #7a7a7a;
        border-radius: 15px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        // 输入框
        input {
          width: 70%;
          height: 100%;
          border: none;
          outline: none;
          background: #7a7a7a;
          color: #ffffff;
        }
        // 修改输入框预输入文字
        input::-webkit-input-placeholder {
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #efefef;
        }
        // 发送
        div {
          width: 24%;
          height: 100%;
          background: #a1a1a1;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 0px 15px 15px 0px;
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #efefef;
          cursor: pointer;
        }
      }
    }
    //隐藏滚动条
    ::-webkit-scrollbar {
      display: none;
      scrollbar-width: none !important;
      -ms-overflow-style: none;
    }
  }
  #logo {
    position: absolute;
    right: 30px;
    bottom: 50px;
    img {
      // width: 100%;
      height: 40px;
    }
  }
}
.full_screen {
  overflow: hidden !important;
  position: fixed !important;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh !important;
  z-index: 99999;
}
</style>
