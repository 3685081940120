import { render, staticRenderFns } from "./ccLives.vue?vue&type=template&id=c52bffb8&scoped=true&"
import script from "./ccLives.vue?vue&type=script&lang=js&"
export * from "./ccLives.vue?vue&type=script&lang=js&"
import style0 from "./ccLives.vue?vue&type=style&index=0&id=c52bffb8&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c52bffb8",
  null
  
)

export default component.exports